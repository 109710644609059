import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Hero from "../components/hero/hero";
import Seo from "../components/seo/seo";
import Layout from "../components/layout";
import ScrollSection from "../components/scroll-section/scroll-section";
import ArticleList from "../components/article-list/article-list";

class RootIndex extends React.Component {
  render() {
    const continents = get(this, "props.data.allContentfulContinent.edges");
    const posts = get(this, "props.data.allContentfulPost.edges");

    return (
      <Layout
        classNames={{ container: "bg-red-lightest" }}
        continents={continents}
        location={this.props.location}
        status={this.props.transitionStatus}
      >
        <Seo
          type="page"
          content={{
            title: "Hodophile Travel Blog",
            seoDescription: "Hodophile Travel",
          }}
        />

        <ScrollSection>
          <Hero
            pretitle={{
              title: "HODOPHILE (pl. hodophiles).<br>TRAVEL WRITING",
            }}
            subtitle="From Ancient Greek ὁδός (hodós, “journey”)"
            title="One who loves to travel."
            type="homepage"
          />
        </ScrollSection>

        <ScrollSection>
          <ArticleList posts={posts} />
        </ScrollSection>
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulContinent(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
    allContentfulPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate
          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          excerpt {
            excerpt
            childMarkdownRemark {
              html
            }
          }
          author {
            firstname
            surname
            slug
            shortBio
            twitter
            location
            picture {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                width: 424
                height: 212
              )
            }
          }
          country {
            slug
            title
            continent {
              title
              slug
            }
          }
        }
      }
    }
    allContentfulAuthor(
      filter: { contentful_id: { eq: "6d0BPSq9EAftwVQ3lhXQ2S" } }
    ) {
      edges {
        node {
          firstname
          shortBio
          heroImage: picture {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
  }
`;
